;(() => {
    const countdowns = document.getElementsByClassName('countdown')
    if (!countdowns || countdowns.length === 0) {
        return
    }
    for (const countdown of countdowns) {
        const second = 1000
        const minute = second * 60
        const hour = minute * 60
        const day = hour * 24

        let date = new Date(countdown.dataset.date)
        const addZero = (number) => {
            if (number < 10) {
                return `0${number}`
            }
            return number
        }

        const setClock = () => {
            let now = new Date().getTime()
            let distance = date - now
            let seconds, minutes, hours, days

            if (distance < 0) {
                days = 0
                hours = 0
                minutes = 0
                seconds = 0
            } else {
                days = addZero(Math.floor(distance / day))
                hours = addZero(Math.floor((distance % day) / hour))
                minutes = addZero(Math.floor((distance % hour) / minute))
                seconds = addZero(Math.floor((distance % minute) / second))
            }

            countdown.querySelector('#days').innerText = days
            countdown.querySelector('#hours').innerText = hours
            countdown.querySelector('#minutes').innerText = minutes
            countdown.querySelector('#seconds').innerText = seconds

            const wonderCircleCircumference = 56.5487 // 2 * pi * r, where r=9

            const secondsProgressCircle = countdown.querySelector(
                '.seconds-progress-circle'
            )
            const secondsRemainingPercent = (60 - seconds) / 60
            secondsProgressCircle?.setAttribute(
                'stroke-dashoffset',
                wonderCircleCircumference * secondsRemainingPercent
            )

            const minutesProgressCircle = countdown.querySelector(
                '.minutes-progress-circle'
            )
            const minutesRemainingPercent = (60 - minutes) / 60
            minutesProgressCircle?.setAttribute(
                'stroke-dashoffset',
                wonderCircleCircumference * minutesRemainingPercent
            )

            const hoursProgressCircle = countdown.querySelector(
                '.hours-progress-circle'
            )
            const hoursRemainingPercent = (24 - hours) / 24
            hoursProgressCircle?.setAttribute(
                'stroke-dashoffset',
                wonderCircleCircumference * hoursRemainingPercent
            )

            const daysProgressCircle = countdown.querySelector(
                '.days-progress-circle'
            )
            const daysRemainingPercent = (200 - days) / 200
            daysProgressCircle?.setAttribute(
                'stroke-dashoffset',
                wonderCircleCircumference * daysRemainingPercent
            )
        }
        setInterval(setClock, second)
    }
})()
